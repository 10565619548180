<template>
  <div id="clustrmaps-component"></div>
</template>

<script>
export default {
  name: 'Clustrmap',
  mounted: function () {
    const component = document.getElementById('clustrmaps-component');
    const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '//clustrmaps.com/map_v2.js?d=7YM6CgL1geOcCY7-YLXwiE6Jf7pKgBd_rkCltXI_tew&cl=ffffff&w=a')
      recaptchaScript.setAttribute('id', 'clustrmaps')
      component.appendChild(recaptchaScript)
  }
}
</script>

<style>

</style>