<template>
<div>
  <div class="card-root">
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.user.new" type="primary" :hidden="!counts.user.new">
          <div>USERS&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.user.count}}</p>
    </el-card>
      <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.article.new" type="primary" :hidden="!counts.article.new">
          <div>ARTICLES&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.article.count}}</p>
    </el-card>
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.tag.new" type="primary" :hidden="!counts.tag.new">
          <div>TAGS&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.tag.count}}</p>
    </el-card>
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.like.new" type="primary" :hidden="!counts.like.new">
          <div>LIKES&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.like.count}}</p>
    </el-card>
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.comment.new" type="primary" :hidden="!counts.comment.new">
          <div>COMMENTS&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.comment.count}}</p>
    </el-card>
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.reply.new" type="primary" :hidden="!counts.reply.new">
          <div>REPLIES&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.reply.count}}</p>
    </el-card>
    <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.browser.new" type="primary" :hidden="!counts.browser.new">
          <div>BROWSERS&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.browser.count}}</p>
    </el-card>

   <el-card class="card-item">
      <div slot="header">
        <el-badge :value="counts.record.new" type="primary" :hidden="!counts.record.new">
          <div>RECORDS&nbsp;</div>
        </el-badge>
      </div>
      <p>{{counts.record.count}}</p>
    </el-card>
  </div>
  <div>
    <el-card class="card-item">
      <clustrmap></clustrmap>
    </el-card>
  </div>
</div>
</template>

<script>
import Clustrmap from '../../../components/clustrmap/clustrmap.vue'
export default {
  name: 'Index',
  components: {Clustrmap},
  data () {
    return {
      counts: {
        user: {new: 0},
        article: {new: 0},
        tag: {new: 0},
        like: {new: 0},
        comment: {new: 0},
        reply: {new: 0},
        browser: {new: 0},
        record: {new: 0},
      }
    }
  },
  async mounted () {
    const { data } = await this.$axios.get('/api/admin/count')
      this.counts = data
  }
}
</script>

<style lang="stylus" scoped>
.card-root
  display flex
  flex-wrap wrap
  text-align: center
  margin 20px
  .card-item
    margin 20px
    max-width 300px
    min-width 200px
    .card-text
      font-size 50px
      font-weight bolder
    .card-header
      background-color none
</style>
